import React, { Component } from 'react'
import { render } from 'react-dom'
import SiteNavbar from './navbar/navbar.js'
import Footer from './footer/footer.js'
import Home from './homepage/home'
import './style.scss'
import './_skel.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div>
        {/** Navbar component for website */}
        <SiteNavbar/>

        {/** This is the main component of the website that displays all of the other components:
         * ---> Map component with search bar/search result/statistic component above it
         * ---> Infographic component that displays step-by-step instructions on texting 911
         * ---> FAQ component that displays the FAQ section of the page.
         */}
        <Home/>
        
        {/** Footer component for website */}
        <Footer/>
      </div>
    )
  }
}

render(<App />, document.getElementById('root'))