import React, { Component } from "react";
import { Fade as Hamburger } from 'hamburger-react'
import { Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

class MobileNav extends Component  {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    toggle = (toggled) => {
        this.setState({
            open: toggled,
        })
    }

    render() {
        return (
            <div> 
                <Hamburger size={28} label="Show menu" toggled={this.state.open} onToggle={this.toggle}/>
                {this.state.open && 
                    <div className="mobile-hamburgerContainer">
                        <div id="basic-navbar-nav" className="dropDownMenu">
                            <Nav.Link className="linkStyle mobileLinkStyle" target="_blank" href="https://accessos.io/how-it-works/">
                                How accesSOS Works</Nav.Link>
                            <hr/>
                            <Nav.Link className="linkStyle mobileLinkStyle" onClick={() => {
                                        // Trigger a smooth scroll to the 'steps' div - this way of doing it is required to
                                        // work on chrome browsers.
                                        location.href = "#";
                                        location.href = "#steps"; }}>
                                How Text to 911 Works
                            </Nav.Link>
                            <hr/>
                            <Nav.Link className="linkStyle mobileLinkStyle" href="#FAQ">
                                FAQ
                            </Nav.Link>
                            <hr/>
                            <Nav.Link className="linkStyle mobileLinkStyle" target="_blank" href="https://accessos.io/resources_page/">
                                Resources
                            </Nav.Link>
                            <hr/>
                            <Nav.Link className="linkStyle mobileLinkStyle" target="_blank" href="https://donorbox.org/911textapp-nationwide"> 
                                Donate
                            </Nav.Link>
                            <hr/>
                            <div className="subscribeReminder subscribeDropdown">
                                Keep up to date with accesSOS:  
                                <a style={{padding: '0 0.2em 0 0.2em', fontSize: '1em'}} target="_blank" href="https://accessos.io/contact/"> 
                                    <b><u>Sign up here</u></b> 
                                </a>
                                to get notified.
                            </div>
                        </div>
                        
                    </div>
                }
            </div>
        )
    }
}

export default MobileNav