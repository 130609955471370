import React, { Component } from 'react'

class Content extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        const imageLeft = this.props.imageLeft;
        const isTitleDiv = this.props.isTitle;
        if(isTitleDiv) {
            return (
                <div className='contentStyle contentTitle'>
                    <h1>{this.props.title}</h1>
                    <h2 style={{textAlign:'center'}}>{this.props.instruction}</h2>
                </div>
            )
        } else {
            if (imageLeft) {
                return (
                    <div className='contentStyle contentImageDiv'>
                        <img src={this.props.imgSrc} id='leftSide'/>
                        <div id='rightSide'>
                            <h1 > {this.props.title} </h1>
                            <h2 > {this.props.instruction} </h2>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className='contentStyle contentImageDiv'>
                        <div id='leftSide'>
                            <h1> {this.props.title} </h1>
                            <h2> {this.props.instruction} </h2>
                        </div>
                        <img src={this.props.imgSrc} id='rightSide'/>
                    </div>
                );
            }
        }
    }
    
}
 
export default Content;