import React from 'react'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import GeocoderResult from './geocoderResult.js'
import PSAPStatistic from './statistic.js';
mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

class MapBox extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      map: null,
      geocoder: null,
      lng: -98.579523,
      lat: 39.828972,
      zoom: 3.15,
      language: 'en',
      searchResult: null,
      searchName: null,
      searchCompatibility: null
    };
    this.mapContainer = React.createRef();
    this.onScriptLoad = this.onScriptLoad.bind(this);
    this.setResult = this.setResult.bind(this);
    this.checkCompatability = this.checkCompatability.bind(this);
  }

  checkCompatability(result, counties) {
    let resultType = result.id.split('.')[0];
    // console.log(result);
    if(resultType != 'country') {
        if(resultType != 'region') {
            let resultContext = result.context;
            // Some browser versions have this parameter named as place_name_en-us, while others have it saved
            // as place_name_en-US. This check will prevent getting an undefined place name.
            let fullPlaceName = (result['place_name_en-us'] === undefined) ? result['place_name_en-US'] : 
            result['place_name_en-us'];

            if(fullPlaceName.endsWith('Puerto Rico')) {
                return 1;
            } else if (fullPlaceName.endsWith('Canada')) {
                return 0;
            } else {
                let countyName, stateName;
                for(let i = 0; i < resultContext.length; i++) {
                    if(resultContext[i].id.startsWith('district')) {
                        countyName = (resultContext[i]['text_en-US'] === undefined) ? 
                        resultContext[i]['text_en-us'] : resultContext[i]['text_en-US'];
                    }
                    if(resultContext[i].id.startsWith('region')) {
                        stateName = resultContext[i].short_code.split('-')[1].toLowerCase();
                    }
                }
                if(stateName === "dc") {
                  return counties.get(stateName).get("")["Status"];
                }
                let stateInMap = counties.get(stateName);
                if(stateInMap === undefined) {
                    return 0;
                } else {
                  if(stateInMap.has("all counties")) {
                    return stateInMap.get("all counties")["Status"];
                  } else if(stateInMap.has("for entire commonwealth")) {
                    return stateInMap.get("for entire commonwealth");
                  }
                }

                console.log(result.text);
                if(resultType === 'district') {
                  countyName = result.text;     
                } else if(countyName === 'roanoke') {
                  countyName = "roanoke(CITY)";
                } 
                countyName = countyName.toLowerCase();

                if(countyName.includes('county')){
                  countyName = countyName.split(' county')[0];
                } 
                if(countyName.includes('\'')) {
                  countyName = countyName.split('\'')[0];
                }
                if(countyName === "petersburg borough") {
                  countyName = "petersburg";
                }
                // console.log("County: " + countyName);
                
                var county = stateInMap.get(countyName);
                // console.log(county);
                if(county === undefined) {
                  return 0;
                }
                return county["Status"];
            }
        } else {
            // Edge case if a user searches for a location larger than a county and smaller than a country,
            // this is most likely if a user searchers for a state.
            return 2
        }
    } else {
        // Edge case if a user searches for a country. The only countries available are the US, Puerto Rico, 
        // and Canada.
        return 3
    }
}

  // creates the map with the given id and options
  onScriptLoad() {
    const { lng, lat, zoom, lang } = this.state;
    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/accessos/cl0xdoqo5001t14pg6ngoji6o?optimize=true',
      center: [lng, lat],
      zoom: zoom,
    });
    this.state.map = map;

    /* set marker to red */
    const markerOptions = {
      color: "#DF4154",
      draggable: true
    };

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: markerOptions,
      placeholder: 'Enter your city or county',
      countries: 'ca us pr',
    })

   geocoder.on('result', (e) => {
      this.state.searchCompatibility = this.checkCompatability(e.result, this.props.countyMap);
      this.state.searchName = e.result.place_name;
   })

   this.state.geocoder = geocoder;

    map.addControl(
      new mapboxgl.NavigationControl(),
      'bottom-right'
    );

    this.setState({ language: lang });
    this.props.onMapLoad(map);
  }

  setResult(result) {
    this.setState({
      searchResult: result
    })
  }

  // component function: invoked immediately after a component is mounted (inserted into the tree)
  componentDidMount() {
    this.onScriptLoad();
    document.getElementById('geocoder').appendChild(this.state.geocoder.onAdd(this.state.map));
  }

  render() {
    
    return (
      <div className="mapSection">
        <div className="searchBlock">
          <PSAPStatistic
            map={this.props.countsMap} loaded={this.props.countsLoaded}
          />
          <GeocoderResult
            check={this.state.searchCompatibility}
            name={this.state.searchName}
          />
        </div>
        <div id="mapcontainer">          
          <div id='myDiv'>
            <button id="aa" className="btnStyle toggles btn leftBtn activeAvailable">Available Areas</button>
            <button id="na" className="btnStyle toggles btn rightBtn">Unavailable Areas</button>
          </div>
          {this.props.isLoading ? <div id="loading-container"></div> : ''}
          <div id='mapCanvasDiv' ref={this.mapContainer}>
          </div>
        </div>
      </div>
    )
  }
}

export default MapBox