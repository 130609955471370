import React, { Component } from 'react'

function getReturnMessage(name, compatible) {
    if(compatible === 2) {
        return <div>
                <hr id="resultDivider"/>
                <h2 style={{width: '80%', margin: 'auto'}}>
                    This location is larger than a county. Interact with the map or search for a more
                    specific location (ex. county, city, address) to get a result.</h2>
            </div>
    } else if (compatible === 3) {
        return <div>
                <hr id="resultDivider"/>
                <h2 style={{width: '80%', margin: 'auto'}}>
                    This location is a country. Interact with the map or search for a more specific
                    location (ex. county, city, address) to get a result.</h2>
            </div>
    } else {
        let resultColor, isCompatible, messageText;
        if(compatible === 1) {
            resultColor = '#03A63C';
            isCompatible = 'Yes';
            messageText = <h2>This location has text to 911, learn how to text 911 <a className='stepsLink' href="#steps">here</a>.
            <br></br>Click <a className='stepsLink' href='#mapcontainer'>here</a> to see result on map.</h2>
        } else if (compatible === 0){
            resultColor = '#DF4154';
            isCompatible = 'No';
            messageText = <h2>This location does not have text to 911. Learn how to text 911 using the accesSOS app <a className='stepsLink' target='_blank' href='https://accessos.io/how-it-works/'>here</a>.
            <br></br>Click <a className='stepsLink' href='#mapcontainer'>here</a> to see result on map.</h2>
        } 
        return <div>
            <hr id="resultDivider"/>
            <div style={{width: '80%', margin: 'auto'}}>
                <h1 style={{color: resultColor, paddingBottom: "2rem"}}>{isCompatible}</h1>
                {messageText}
            </div>
        </div>
    }
}

class GeocoderResult extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const resultName = this.props.name;
        const resultCompatibility = this.props.check;
        if(resultName === null) {
            return (
                <div className="resultDiv">
                    <h1 style={{paddingBottom:0, marginBottom: 0}}>
                        Check if text to 911 is available in your area</h1>
                    <div id="geocoder"></div>
                </div>
            );
        } else {
            return ( 
                <div className="resultDiv">
                    <h1> Check if text to 911 is available in:</h1>
                    <div id="geocoder"></div>
                    {getReturnMessage(resultName, resultCompatibility)}
                </div> 
            )
        }
    }
}

export default GeocoderResult;