import React, {Component} from 'react'
import {Button, Collapse} from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.css';

class CollapsibleButton extends Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            padding: '0px'
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
          open: !this.state.open,
        });
        let newPadding;
        if(this.state.open) {
          newPadding = '0vw 1vw'
        } else {
          newPadding = '3vw 1vw'
        }
        this.setState({
          padding: newPadding
        })
      }

    render(){
      return(
        <div>
           <Button className="questionBtn" onClick={this.handleClick}>
               <span>{this.props.btnName}</span>
               <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1.00195 1.00146L9.00195 9.00247L17.002 1.00146" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                 </path>
              </svg>
           </Button>
            <div style={{ width: '100%', padding: this.state.padding}}>
           <Collapse in={this.state.open}>
               <div className="questionResponse">
                  {this.props.btnText}
               </div>
           </Collapse>
           </div>
        </div>
        );
       }
}

export default CollapsibleButton;
