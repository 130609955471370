import React, { Component } from 'react'
// import '../style.scss'
// import '../_skel.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';
import CollapsibleButton from './collapsibleButton'

class FAQ extends Component {
    constructor(props) {
        super(props)
    }

    render() { 
        const date1 = this.props.firstDate;
        const date2 = this.props.secondDate;
        let compatible = this.props.compatiblePSAPs;
        let total = this.props.totalPSAPs;
        let counties = this.props.countyTotal;
        const percentage = Math.trunc((compatible/total) * 100);
        const text = [
            {
                key: 0,
                question: "My local 911 call center - also known as a Public Safety Answering Point (PSAP) or emergency call center (ECC) - has text to 911 "
                 + "but I don't see it on here. How can we fix this?",
                answer: <p>
                    Our data comes from the Federal Communication Commission’s (FCC) <a href='https://www.fcc.gov/files/text-911-master-psap-registryxlsx' target='_blank'>database</a>.
                    If you are a PSAP that has text to 911 enabled and want this reflected on text911.info's map, please register your PSAP using the FCC's <a href='https://www.fcc.gov/general/psap-text-911-readiness-and-certification-form\' target='_blank'>PSAP</a> Text-to-911 <a href='https://www.fcc.gov/file/21217/download' target='_blank'>Readiness & Certification Form</a>.</p>
            },
            {
                key: 1,
                question: "How was the percentage of Text to 911 compatible PSAPs above the search bar calculated?",
                answer: <p>
                    The number of text to 911 compatible counties was tallied from the entries in the <a href="https://www.fcc.gov/files/text-911-master-psap-registryxlsx" target='_blank'>FCC's Text 911 Master PSAP Registry</a> file (Last updated on {date1}) that were marked as such.<br></br><br></br>
                    The total number of PSAPs in the U.S., were tallied from the entries in the <a href='https://www.fcc.gov/general/9-1-1-master-psap-registry' target='_blank'>FCC's 911 Master PSAP Registry</a>  file (Last updated on {date2}) that were marked as newly Added (A), Modified (M), or Unchanged (NC).<br></br><br></br>
                    From this, we get a compatible PSAPs count of <span>{compatible}</span> - across {counties} counties - and a total PSAPs count of <span>{total}</span> which tells us <span>{percentage}%</span> of PSAPs in the US are text to 911 compatible.
                </p>
            },
            {
                key: 2,
                question: "How does the map account for multiple PSAPs within a county?",
                answer: <p>
                    In its current state, the map will display a county as text to 911 compatible if it has <span>at least one</span> PSAP registered as text to 911 compatible in the FCC's Text 911 Master PSAP Registry.
                    {" "}There are cases where one PSAP in a county may support text to 911 but another may not. For these cases, they are <span>not currently</span> reflected in the map, but accesSOS fully intends to 
                    {" "}incorporate this functionality in the future.
                </p>
            }, 
            {
                key: 3,
                question: "I encountered issues with the website and/or have suggestions for how to improve it. Where can I send feedback for accesSOS developers?",
                answer: <p>
                    Thank you for your interest in improving the text911.info website! Please submit any feedback regarding your user experience {" "}
                    through this <a href="https://docs.google.com/forms/d/e/1FAIpQLSdkOcFTCy4YY0zuz6ShyhoMrhJtShGQ-Z632y3F8ccJsus1CQ/viewform?usp=sf_link" target="_blank">google form</a>.
                </p>
            }
        ]
        const faqItems = text.map((text) =>
            <CollapsibleButton key={text.key}
            btnName={text.question}
            btnText={text.answer}/>
        );
        return(
        <div className="questionDiv" id="FAQ">
            <div className="contentStyle contentTitle faqTitle">
              <h1>General Questions (FAQ)</h1>
            </div>
            { faqItems }
        </div>)
    }
}
 
export default FAQ;