import React, { Component } from 'react'

class Footer extends Component {
    render() { 
        return (
            <div className="footerContainer">
                <div>
                    <div className="footerLinksContainer">
                        <div className="footerItem"> 
                            <h3> accesSOS </h3>
                            <div className="footerLinks">
                                <a href="https://accessos.notion.site/Team-accesSOS-Public-Directory-68a3d83542d14bbbafc6d156a665c86b" target="_blank" rel="noreferrer"> Team Access 🤟 </a>
                                <a href="https://www.notion.so/accessos/5c09927c38934ddeafcfc79768b5e23b?v=47399d6f10f74d2f97de52f3d70e9d04" target="_blank" rel="noreferrer"> Blog </a>
                                <a href="https://www.notion.so/accessos/Job-Board-external-4cf4579f919a42228d1d6818b196dadf" target="_blank" rel="noreferrer"> Careers {"&"} Internships </a>
                            </div>
                        </div>
                        <div className="footerItem"> 
                            <h3> Learn </h3>
                            <div className="footerLinks">
                                <a href="https://www.notion.so/accessos/UX-Research-d7a14e8b5d434e6eb1b57dd9aba459ec" target="_blank" rel="noreferrer"> Our Research </a>
                                <a href="https://www.notion.so/accessos/Media-Kit-45ff75414e2f442099f7008801689572" target="_blank" rel="noreferrer"> Media Kit </a>
                            </div>
                        </div>
                        <div className="footerItem"> 
                            <h3> Support </h3>
                            <div className="footerLinks">
                                <a href="https://donorbox.org/911textapp-nationwide" target="_blank" rel="noreferrer"> Donate </a>
                                <a href="https://accessos.notion.site/Probono-Volunteer-Mini-Projects-176057538c48444e8117eb4894da22bb" target="_blank" rel="noreferrer"> Volunteer </a>
                                <a href="https://www.facebook.com/groups/access911" target="_blank" rel="noreferrer"> Our Advocacy Project </a>
                            </div>
                        </div>
                        <div className="footerItem"> 
                            <h3> Social </h3>
                            <div className="mediaLinks">
                                <a href="https://www.facebook.com/911access" target="_blank" rel="noreferrer">
                                    <svg height="20" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0192 10.6253H7.16412V21.0847H2.83857V10.6253H0.78125V6.94941H2.83857V4.57068C2.83857 2.86969 3.64657 0.206055 7.20262 0.206055L10.4068 0.219463V3.78756H8.08196C7.70059 3.78756 7.1644 3.97804 7.1644 4.7895V6.9528H10.3971L10.0192 10.6253Z" fill="#DF4154"/>
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/911access/" target="_blank" rel="noreferrer"> 
                                    <svg height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.7718 0.172852H3.16891C1.50169 0.172852 0.167969 1.50649 0.167969 3.17359V12.7759C0.167969 14.443 1.50169 15.7766 3.16891 15.7766H12.7718C14.4389 15.7766 15.7726 14.443 15.7726 12.7759V3.17359C15.7726 1.50649 14.4389 0.172852 12.7718 0.172852ZM13.6387 1.97331H13.9721V4.64061H11.3046V1.97331H13.6387ZM5.76965 6.37435C6.23646 5.70752 7.10338 5.2407 7.97029 5.2407C8.83721 5.2407 9.70421 5.70752 10.1709 6.37435C10.5044 6.84108 10.7044 7.37454 10.7044 7.97473C10.7044 9.50837 9.43742 10.7087 7.97029 10.7087C6.50327 10.7087 5.23615 9.50847 5.23615 7.97473C5.23615 7.37454 5.43625 6.84108 5.76965 6.37435ZM14.2389 12.7759C14.2389 13.576 13.572 14.2429 12.7718 14.2429H3.16891C2.36869 14.2429 1.70179 13.576 1.70179 12.7759V6.37435H4.03582C3.83572 6.84108 3.70242 7.44127 3.70242 7.97473C3.70242 10.3087 5.63626 12.2424 7.97029 12.2424C10.3043 12.2424 12.2383 10.3087 12.2383 7.97473C12.2383 7.44127 12.1049 6.84108 11.9048 6.37435H14.2388L14.2389 12.7759Z" fill="#DF4154"/>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/911access" target="_blank" rel="noreferrer">
                                    <svg height="20" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.06 4.77458C18.0683 4.94747 18.0719 5.12091 18.0719 5.29637C18.0719 10.6252 14.0161 16.7687 6.59946 16.7687C4.32288 16.7687 2.20265 16.1015 0.417969 14.9579C0.734312 14.9949 1.05431 15.0138 1.3798 15.0138C3.26963 15.0138 5.00769 14.3692 6.38735 13.288C4.62369 13.2548 3.13523 12.0885 2.6214 10.4868C2.86734 10.5349 3.11969 10.5589 3.38026 10.5589C3.7478 10.5589 4.10346 10.5108 4.44174 10.4179C2.59671 10.0475 1.207 8.41664 1.207 6.46464C1.207 6.44718 1.207 6.43026 1.20792 6.41271C1.751 6.71488 2.37271 6.89609 3.03374 6.91758C1.95123 6.1931 1.23991 4.96046 1.23991 3.56087C1.23991 2.82203 1.43923 2.12873 1.78574 1.53353C3.77523 3.97285 6.74665 5.57842 10.0975 5.74601C10.0289 5.4517 9.99328 5.14304 9.99328 4.82725C9.99328 2.60114 11.7981 0.79541 14.0253 0.79541C15.1855 0.79541 16.2333 1.2853 16.9684 2.0683C17.8872 1.88837 18.7503 1.55173 19.5293 1.08976C19.2267 2.03191 18.5885 2.82203 17.7556 3.32041C18.572 3.22295 19.3492 3.0059 20.0715 2.68562C19.5329 3.49586 18.8481 4.20608 18.06 4.77458Z" fill="#DF4154"/>
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/accessos/" target="_blank" rel="noreferrer">
                                    <svg height="20" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.679429 5.25186H4.30579V16.1464H0.679429V5.25186ZM4.5439 1.88179C4.52037 0.8136 3.75709 0 2.51662 0C1.27615 0 0.464844 0.8136 0.464844 1.88179C0.464844 2.92791 1.25261 3.76489 2.46955 3.76489H2.49308C3.75708 3.76489 4.5439 2.92791 4.5439 1.88179ZM13.2074 4.99605C15.5933 4.99605 17.3825 6.55351 17.3825 9.89977L17.3815 16.1464H13.7561V10.3178C13.7561 8.85384 13.231 7.85478 11.919 7.85478C10.9175 7.85478 10.3209 8.52828 10.0583 9.17882C9.96226 9.41186 9.93872 9.73666 9.93872 10.0623V16.1466H6.31237C6.31237 16.1466 6.36037 6.27445 6.31237 5.25224H9.93872V6.79529C10.4206 6.05346 11.2818 4.99605 13.2074 4.99605Z" fill="#DF4154"/>
                                    </svg>
                                </a>
                                <a href="https://www.tiktok.com/@911access?lang=en" target="_blank" rel="noreferrer">
                                    <svg height="20" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.9876 0.705322C17.4126 4.36851 19.4525 6.55249 22.9993 6.78483V10.905C20.9439 11.1063 19.1435 10.4325 17.0494 9.16242V16.8683C17.0494 26.6575 6.40149 29.7166 2.12069 22.7C-0.63015 18.1849 1.05435 10.2621 9.87868 9.94462V14.2893C9.20643 14.3978 8.48781 14.5681 7.831 14.7927C5.86833 15.4588 4.75563 16.7057 5.06471 18.9051C5.65969 23.1182 13.3713 24.3651 12.73 16.1326V0.713067H16.9876V0.705322Z" fill="#DF4154"/>
                                    </svg>  
                                </a>
                                <a href="https://www.youtube.com/channel/UCzeRKkzcRAmGE_3nNAmInoA" target="_blank" rel="noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="-35.20005 -71.33325 405.0671 247.9995">
                                        <path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill="#DF4154"/>
                                        <path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#fff"/>
                                    </svg>  
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="footer-end">
                        <h4> © 2022 accesSOS. All rights reserved. accesSOS, EIN 84-2489856, is a registered nonprofit 501(c)(3) organization. </h4>
                        <div id="listofcredits">
                            <a target="_blank" id="credit" href="https://www.notion.so/accessos/Terms-of-Use-Privacy-Policy-7eeb29adf43946db8abe287997a3004f">
                                Terms of Use and Privacy Policy
                            </a>
                            <a target="_blank" id="credit" href="https://www.census.gov/about/policies/citation.html">County boundary data from U.S Census Bureau</a>
                            <a target="_blank" id="credit" href="https://eric.clst.org/tech/usgeojson/">GeoJson Overlay County File</a>
                            <a target="_blank" id="credit" href="https://github.com/codeforamerica">GeoJson Canada File</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Footer