import Content from "./contentDiv";
import React, { Component } from 'react'

class TextInstructions extends Component {
    render() { 
        return (<div id="steps">
            <Content 
                isTitle={true}
                title="How to Text 911"
                instruction="If text to 911 works in your area, here's how to text 911 for help."/>
            <Content
                isTitle={false} 
                imgSrc="../Step1Phone.png"
                title="Step 1"
                instruction="On your phone, open a new text message, input 911."
                imageLeft={true}/>
            <Content
                isTitle={false} 
                imgSrc="../Step2Phone.png"
                title="Step 2"
                instruction="Provide an address."
                imageLeft={false}/>
            <Content 
                isTitle={false}
                imgSrc="../Step3Phone.png"
                title="Step 3"
                instruction="State if you need medical, police, and/or fire emergency services."
                imageLeft={true}/>
        </div>);
    }
}
 
export default TextInstructions;